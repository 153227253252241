import React, { useEffect, useRef, useState } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Html } from '@react-three/drei';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';


const Model = ({ modelPath, position, scale, rotation }) => {
  const [modelLoaded, setModelLoaded] = useState(false);
  const modelRef = useRef();

  const gltf = useLoader(GLTFLoader, modelPath, (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
    loader.setDRACOLoader(dracoLoader);
  });

  useEffect(() => {
    if (gltf && !modelLoaded) {
      const model = gltf.scene.clone();
      model.position.set(...position);
      model.scale.set(...scale);
      model.rotation.set(...rotation);
      modelRef.current = model;
      setModelLoaded(true);
    }
  }, [gltf, modelLoaded, position, scale]);

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.000;
    }
  });

  return (
    <>
      {!modelLoaded && (
        <Html center>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            fontSize: '1.5rem',
            color: 'black',
            whiteSpace: 'nowrap'
          }}>
            <p>Please wait until the model loads...</p>
          </div>
        </Html>
      )}
      {modelLoaded && (
        <> <primitive object={modelRef.current} />
        </>
      )}
    </>

  );
};

export default Model;