import React, { useEffect, useState } from 'react'
import Content from '../layout/content/Content'
import Home from './silosecuredata/components/Home'
import Services from './silosecuredata/components/Services'
import Solutions from './silosecuredata/components/Solutions'
import ChooseUs from './silosecuredata/components/ChooseUs'
import Contactus from './silosecuredata/components/Contactus'
import Projects from './silosecuredata/components/Projects'
import WelcomeModal from './components/common/WelcomeModel'

const SiloSecureData = () => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      sessionStorage.removeItem('modalShown');
    };

    window.addEventListener('beforeunload', handlePageLoad);

    const hasModalBeenShown = sessionStorage.getItem('modalShown');

    if (!hasModalBeenShown) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem('modalShown', 'true');
      }, 2500);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('beforeunload', handlePageLoad);
      };
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <React.Fragment>
      <Content>
        <Home />
        <Services />
        <Projects />
        <Solutions />
        <ChooseUs />
        <Contactus />
      </Content>
      <WelcomeModal
        isOpen={isOpen}
        onClose={closeModal}
        fontClass="ff-alt"
        title="Unlock Your Business Potential with a Free Consultation!"
        subtitle="Get expert advice on how our software solutions can drive your success."
        leftColumnTitle="What You Get:"
        leftColumnItems={[
          "Comprehensive analysis of your business needs",
          "Personalized software recommendations",
          "Roadmap to implement effective solutions"
        ]}
        rightColumnTitle="Why Choose Us:"
        rightColumnItems={[
          "Identify the best software solutions for your business",
          "Receive expert guidance from experienced professionals",
          "Get a tailored strategy to boost productivity and efficiency"
        ]}
        ctaButtonText="Schedule Now"
        ctaButtonLink="/bookconsultation"
        footerTitle="Schedule Your Free Consultation Today!"
        phoneNumber="833-468-6648"
        email="support@silocloud.io"
      />
    </React.Fragment>
  )
}

export default SiloSecureData
