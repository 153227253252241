import React from 'react'
import { Block } from '../../../components/Component'
import { Col, Container, Row } from 'reactstrap'
import CEOimg from '../../../images/siloimages/Dr.Lael3.jpg'

const AboutUs = () => {
    return (
        <React.Fragment>
            <Block>
                <div className='container my-5 py-md-5 py-3'>
                    <div className='nk-content py-0'>
                        <Container className='my-4'>
                            <div className='d-flex justify-content-center'>
                                <h2 className='ff-alt text-dark'>
                                    About Us
                                </h2>
                            </div>
                            <div className='d-flex justify-content-center py-3 '>
                                <h4>
                                    <p className='ff-alt text-dark text-center fw-medium'>
                                        We are on a mission to make the web a better place
                                    </p>
                                </h4>
                            </div>
                            <div className='p-md-4 p-0 text-dark mx-lg-5 mx-2'>
                                <p className='ff-alt'>
                                    We provide a remote cloud browser that includes security, ownership, and data policies that protect your computer from web exploitation. A cloud browser that keeps the threats away from the user's device and internal networks to keep the business safe.
                                </p>
                                <p className='ff-alt'>
                                    SiloCloud is a cloud computing service that runs on the same infrastructure that allows you to use products such as Silo Cloud Storage, Silo Chat, and Mail. SiloCloud delivers the blockchain services and Web3 development tools that power real business transformations. Create new businesses based on revolutionary decentralized networks that change your world forever.
                                </p>
                                <p className='ff-alt'>
                                Build, Host, and Stream your dream on our decentralized network, which includes services and consultancy that assist you in the creation of your own dedicated network to help you run your business totally distributed and secure.
                                </p>
                            </div>
                        </Container>
                        <Container className='border border-3 border-danger rounded-5 shadow-lg p-3'>
                            <Row>
                                <p className='fs-4 ff-alt text-capitalize text-dark text-center'>meet the founder</p>
                                <Col md={12} lg={6}>
                                    <div className="container-fluid pb-3 pb-lg-0">
                                        <div>
                                            <h1 className='ff-alt text-uppercase text-dark fw-medium d-grid '>
                                                <span className='text-danger'>Lael </span>
                                                <span >Alexander</span>
                                            </h1>
                                        </div>
                                        <div className='py-3'>
                                            <h3 className='ff-alt fw-normal'>
                                                Founder & CEO Silocloud
                                            </h3>
                                        </div>
                                        <div>
                                            <p className='ff-alt'>
                                                Dr. Lael Alexander, a visionary entrepreneur and consumer electronics designer, has embarked on a remarkable journey that has taken him from being an inventor in America to achieving worldwide recognition as the top consumer electronics designer in China. His inspiring story, titled "Born in America, Made in China," showcases his groundbreaking contributions to the technology industry and his unwavering resilience in the face of adversity.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} lg={6}>
                                    <div className="container-fluid h-100 d-flex align-items-center">
                                        <img src={CEOimg} alt="CEOimg" className='img-fluid rounded-5' />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <Portfoliosilo /> */}
                </div>
            </Block>
        </React.Fragment>
    )
}

export default AboutUs
