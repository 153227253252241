import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import silo from '../../../images/siloimages/silocloud.png';
import dolr from '../../../images/siloimages/Dolr Coin Black.png';
import nuairs from '../../../images/siloimages/Nuair_BLACK_.png';
import representme from '../../../images/siloimages/representme.png';
import realflow from '../../../images/siloimages/realflow.png';

const ProjectItem = ({ name, url, imageSrc, altText, className, style }) => (
  <Col xs={6} sm={4} md={4} lg={2} className="mb-4">
    <a href={url} target='_blank' className="text-decoration-none">
      {/* <h5 className="px-1 ff-alt fw-semibold text-center text-dark mb-2">{name}</h5> */}
      <div className={`text-center ${className || ''}`}>
        <img src={imageSrc} alt={altText} className="img-fluid image_shadow" style={{ ...style }} />
      </div>
    </a>
  </Col>
);

const Projects = () => {
  const projectData = [
    { name: 'SiloCloud', url: 'https://silocloud.com/', imageSrc: silo, altText: 'SiloCloud' },
    { name: 'NuAirs', url: 'https://nuairs.com/', imageSrc: nuairs, altText: 'NuAirs' },
    { name: 'DOLRCoin', url: 'https://dolrcoin.com/', imageSrc: dolr, altText: 'DOLRCoin' },
    { name: 'Therealflow', url: 'https://therealflow.com/', imageSrc: realflow, altText: 'Therealflow', style: { maxWidth: '70%' } },
    { name: 'RepresentingMe', url: 'https://representingme.com/', imageSrc: representme, altText: 'RepresentingMe' }
  ];

  return (
    <React.Fragment>
      <Container fluid className="py-5">
        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h3 className="ff-alt fw-semibold text-dark text-capitalize text-center pb-md-5 pb-2 " style={{ color: "#17253D" }}>
              Our Projects
            </h3>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {projectData.map((project, index) => (
            <ProjectItem key={index} {...project} />
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Projects;