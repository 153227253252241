import React from 'react';
import { Card, CardImg, Col, Row } from 'reactstrap';
import SlideA from "../../../images/siloimages/Portfolio/Games&Tools/Asembler_Tool.jpg";
import SlideB from "../../../images/siloimages/Portfolio/Games&Tools/ConstructorTool.jpg";
import SlideC from "../../../images/siloimages/Portfolio/Games&Tools/DurbyRace.jpg";
import SlideD from "../../../images/siloimages/Portfolio/Games&Tools/OpenWorld.jpg";

const GamesAndTools = () => {
    const projects = [
        { image: SlideB, title: "Silo Constructor Tool", description: "You can create 3D designs easily here with no design knowledge; simply drag and drop elements, and your 3D design is ready for print. This constructor tool provides a user-friendly design process. This tool provides flexible design, rapid prototyping, and fast rendering for the best quality experience for designers.", link: "https://silocloud.com/silo-constructor" },
        { image: SlideC, title: "Silo Durby Race", description: "CryptoDerby is a decentralized racing game where gamers can participate in the game where their horse's stats are determined by how many miners are mining for their horse and miner's resources.", link: "https://cryptoderby.org/" },
        { image: SlideA, title: "Silo Assembler Tool", description: "Silo Assembler is a constructing tool to construct structures like warehouses, storage units, and many more. Also, you can build furniture of your choice, change texture and have a preview of 360.", link: "" },
        { image: SlideD, title: "Silo Open World", description: "Silo Open World.com is an online platform for real estate property listings. Siloopenworld.com allows its users (real estate agents or individual sellers) to post their listings and share information about the properties available for sale or rent.", link: "https://silocloud.com/cloud/home/login" },
    ];

    return (
        <div className='container'>
            <Row className='g-4'>
                {projects.map((project, index) => (
                    <Col md='6' className='alighn-self-center' key={index}>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                            <Card className="hover-game_card d-flex align-items-center">
                                <div className="game_image-container">
                                    <CardImg top src={project.image} alt={project.title} className="game_card-image" />
                                    <div className="overlay">
                                        <h3 className='ff-alt text-danger'>{project.title}</h3>
                                        <p className='ff-alt'>{project.description}</p>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default GamesAndTools;
