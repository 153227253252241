import React from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import slideA from '../../../../../images/axiom_img/Static&Dynamic_img/Static Web.png';
import slideB from '../../../../../images/axiom_img/Static&Dynamic_img/Dynamic Website.png';
import slideC from '../../../../../images/axiom_img/Static&Dynamic_img/responsive.png';
import slideD from '../../../../../images/axiom_img/Static&Dynamic_img/online-shopping.png';
import slideE from '../../../../../images/axiom_img/Static&Dynamic_img/erp.png';
import slideF from '../../../../../images/axiom_img/Static&Dynamic_img/app-development.png';
import Content from '../../../../../layout/content/Content';

const services = [
    {
        name: "Static Website Development",
        description: "Ideal for small businesses and personal portfolios, static websites provide a high level of security, load quickly and require minimal web hosting resources.",
        benefits: ["Increased security", "Fast loading times", "Cost-effective hosting"],
        price: "Contact for pricing",
        image: slideA
    },
    {
        name: "Dynamic Website Development",
        description: "Dynamic websites are perfect for sites requiring regular updates and interactions, such as user profiles, custom searches and real-time data displays.",
        benefits: ["Interactive features", "User engagement", "Scalability"],
        price: "Contact for pricing",
        image: slideB
    },
    {
        name: "Responsive Website Design",
        description: "Ensure your website looks great on all devices, from desktops to smartphones, enhancing user experience and accessibility.",
        benefits: ["Improved accessibility", "Better UX", "SEO advantages"],
        price: "Contact for pricing",
        image: slideC
    },
    {
        name: "E-commerce Web Development",
        description: "Build a thriving online store with our e-commerce solutions, featuring secure payment integration, inventory management and user-friendly designs.",
        benefits: ["Secure payment gateways", "Inventory management", "Customizable designs"],
        price: "Contact for pricing",
        image: slideD
    },
    {
        name: "ERP / CRM Development",
        description: "Our custom ERP/CRM systems streamline processes, centralize data and enhance customer engagement to boost efficiency and growth.",
        benefits: ["Streamlined Business Operations", "Improved Data Management", "Enhanced Customer Relationships"],
        price: "Contact for pricing",
        image: slideE
    },
    {
        name: "Mobile Application Development",
        description: "Axiom excels in mobile application development, crafting innovative and user-centric applications that enhance engagement and foster business growth.",
        benefits: ["Enhanced Customer Engagement", "Increased Operational Efficiency", "Expanded Market Reach"],
        price: "Contact for pricing",
        image: slideF
    }
];

const ServiceCard = ({ service }) => {
    return (
        <Col md={4} className="mb-4">
            <Card className='h-100 bg-primary-subtle'>
                <CardBody>
                    <CardTitle className='ff fs-4 fw-bold text-primary'>{service.name}</CardTitle>
                    <CardText className='ff fs-6 text-primary'>{service.description}</CardText>
                    <Row className='mt-2'>
                        <Col lg='7' md='12' >
                            <div className="list ff text-primary">
                                <ul>
                                    {service.benefits.map(benefit => (
                                        <li key={benefit}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col lg='5' md='12'>
                            <div className='p-3 d-flex justify-content-center'>
                                <img src={service.image} alt={`${service.name}`} className='img-fluid responsive-image' />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <div className="fw-bold ff py-3 d-md-flex justify-content-center d-none ">Pricing: {service.price}</div>
            </Card>
        </Col>
    );
};

const ServicesSection = () => {
    return (
        <Content className='my-5 container-fluid'>
            <h2 className="my-5 text-center ff fs-1 text-primary">Services we offer you</h2>
            <div className='px-3 '>
                <Row>
                    {services.map(service => (
                        <ServiceCard key={service.name} service={service} />
                    ))}
                </Row>
            </div>
        </Content>
    );
};

export default ServicesSection;
