import React, { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { serviceData } from "../../../components/forms/SelectData";
import makeAnimated from "react-select/animated";
import imgUrl from '../../../../images/axiom_img/background5.png';
import Content from '../../../../layout/content/Content';
import { RSelect } from '../../../../components/Component';
import Required from '../../../components/Required';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const ContactUs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const animatedComponents = makeAnimated();
    const [formDisabled, setFormDisabled] = useState(false);

    const handleCaptcha = (value) => {
        setCaptchaValue(value);
    };
    const initialdata = {
        firstname: "",
        lastname: "",
        phoneno: "",
        services: [],
        email_user: "",
        description: "",
    }
    const [formData, setFormData] = useState(initialdata);
    const [errors, setErrors] = useState({});
    const handlePhoneChange = (value) => {
        setFormData(prevState => ({
            ...prevState,
            phoneno: value,
        }));
    };
    const handleChange = (e, name) => {
        let value;
        if (e && e.target) {
            ({ name, value } = e.target);
        } else if (Array.isArray(e)) {
            value = e;
        }

        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: '' }));
        // console.log(formData);
        // if (name === 'phoneno') {
        //     // Remove all non-digit characters
        //     const digitsOnly = value.replace(/\D/g, '');

        //     if (digitsOnly.length !== 10 && digitsOnly.length !== 14) {
        //         setErrors(prevState => ({ ...prevState, [name]: 'Phone number must be 10 digits, or 14 digits with country code' }));
        //     } else if (digitsOnly.length === 14 && !digitsOnly.startsWith('1')) {
        //         setErrors(prevState => ({ ...prevState, [name]: 'For 14-digit numbers, country code must start with 1' }));
        //     }
        // }
        if (name === 'email_user') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrors(prevState => ({ ...prevState, [name]: 'Enter a valid email address' }));
            }
        }
    };
    const formSubmit = async (formData) => {
        // console.log("execution starts");
        const formPayload = new FormData();
        formPayload.append("note", formData.description);
        formPayload.append("email", formData.email_user);
        formPayload.append("first_name", formData.firstname);
        formPayload.append("last_name", formData.lastname);
        formPayload.append("phone", formData.phoneno);
        formPayload.append("services", JSON.stringify(formData.services));
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/contact-us`, {
            method: "POST",
            body: formPayload,
        });
        const data = await response.json();
        // console.log(data);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const isEmpty = Object.values(formData).some(value => value === "" || value.length === 0);
        if (isEmpty) {
            toast.error("All fields are required.");
            setIsLoading(false);
            return;
        }
        if (errors.phoneno || errors.email_user) {
            toast.error("Please enter a valid phone number and email address.");
            setIsLoading(false);
            return;
        }
        try {
            if (!captchaValue) {
                toast.error("Please complete the reCAPTCHA");
                return;
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));

            setIsSubmitted(true);
            toast.success("Thank you for contacting us, We will reach back to you very soon!", {
                autoClose: 3000,

            });

            formSubmit(formData)
            // console.log(formData);
            // console.log("Function calls");
            setFormData(initialdata);
            setCaptchaValue(null);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }

            setFormDisabled(true);
            setTimeout(() => {
                setFormDisabled(false);
                setIsSubmitted(false);
            }, 50000);

        } catch (error) {
            toast.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <Content>
                <div className="container-fluid p-3 m-auto" id='contactus' style={{
                    backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }} >
                    <div className="row p-md-4 m-auto justify-content-around p-1 border-0 " data-aos="fade-down" data-aos-duration="3000">
                        <div className="col-md-10 p-md-4" id='formim'>
                            <div className=" p-md-4 p-0 ">
                                <h3 className="text-primary ff">
                                    Turn Your Product Vision Into Reality
                                </h3>
                            </div>
                            <div className="conntainer">
                                {isSubmitted && <div className="text-success d-flex justify-content-center pb-4 fs-5">Thank you! We will be in touch soon.</div>}
                                <form className='p-md-4 pb-2 ' id='feedbackForm' onSubmit={handleSubmit}>
                                    <div className="form-row d-md-flex pb-2 pb-md-0 justify-content-center ">
                                        <div className="form-group col-md-5 px-2 ">
                                            <label htmlFor="input1" className="form-label">First Name<Required /></label>
                                            <input type="text" className="form-control" id="input1" placeholder='Enter First-Name' name="firstname" onChange={handleChange} value={formData.firstname} required disabled={formDisabled} />
                                        </div>
                                        <div className="form-group col-md-5 px-2 ">
                                            <label htmlFor="input2" className="form-label">Last Name<Required /></label>
                                            <input type="text" className="form-control" id="input2" placeholder='Enter Last-Name' name="lastname" onChange={handleChange} value={formData.lastname} required disabled={formDisabled} />
                                        </div>
                                    </div>
                                    <div className="form-row d-md-flex pb-2 pb-md-0 justify-content-center ">
                                        <div className="form-group col-md-5 px-2 ">
                                            <label htmlFor="input3" className="form-label">Email<Required /></label>
                                            <input type="email" className="form-control" id="input3" placeholder='Enter Email Address' name="email_user" onChange={handleChange} value={formData.email_user} required disabled={formDisabled} />
                                            {errors.email_user && <div className="text-danger">{errors.email_user}</div>}
                                        </div>
                                        <div className="form-group col-md-5 px-2">
                                            <label htmlFor="input4" className="form-label">Phone No<Required /></label>
                                            <PhoneInput
                                                placeholder="Enter Phone Number"
                                                name="phoneno"
                                                value={formData.phoneno}
                                                onChange={handlePhoneChange}
                                                className={`rounded`}
                                                error={formData.phoneno ? (isValidPhoneNumber(formData.phoneno) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                rules={{ required: true, validate: isPossiblePhoneNumber }} disabled={formDisabled}
                                            />
                                            {/* <input type="number" className="form-control" id="input4" placeholder="Enter Phone Number (e.g., 1234567890 or 11234567890)" name="phoneno" onChange={handleChange} value={formData.phoneno} required minLength={10}
                                                maxLength={14} disabled={formDisabled} /> */}
                                            {errors.phoneno && <div className="text-danger">{errors.phoneno} </div>}
                                        </div>
                                    </div>
                                    <div className="form-row d-md-flex pb-2 pb-md-0 justify-content-center mb-3">
                                        <div className="form-group col-md-10 px-2 justify-content-center">
                                            <label className="form-label">Select Services<Required /></label>
                                            <RSelect closeMenuOnSelect={false} components={animatedComponents} isMulti options={serviceData} name="services" onChange={(selectedOption) => handleChange(selectedOption, 'services')} value={formData.services} required disabled={formDisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row d-md-flex pb-2 pb-md-0 justify-content-center mb-3">
                                        <div className="form-group col-md-10 px-2 justify-content-center ">
                                            <label htmlFor="input7" className="form-label">How can we help you?<Required /></label>
                                            <textarea type="textarea" className="form-control" id="input6" placeholder='Please explain in detail' name="description" onChange={handleChange} value={formData.description} required disabled={formDisabled} />
                                        </div>
                                    </div>
                                    <div className="form-row g-3 d-md-flex pb-2 pb-md-0 justify-content-center">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                                            onChange={handleCaptcha}
                                            ref={recaptchaRef}
                                        />
                                        <div className="form-row align-self-center ">
                                            <Button type="submit" className='px-5 py-2 fs-6 ff-alt button' disabled={isLoading}>{isLoading ? 'Loading...' : 'Submit'}</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default ContactUs
