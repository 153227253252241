import { Canvas } from "@react-three/fiber";
import Model from "./Model";
import { Environment, OrbitControls } from "@react-three/drei";
import { Suspense } from "react";

const FullScreenModel = ({ model, onClose }) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.9)',
            zIndex: 9999,
        }}>
            <button
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    zIndex: 10000,
                    background: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    fontSize: '20px',
                    cursor: 'pointer',
                }}
            >
                ×
            </button>
            <Canvas shadows camera={{ position: [0, 0, 2], fov: 50 }} style={{ width: '100%', height: '100%' }}>
                <color attach="background" args={["#efefef"]} />
                <ambientLight intensity={0.3} />
                <directionalLight
                    castShadow
                    position={[5, 5, 5]}
                    intensity={0.5}
                    shadow-mapSize={[1024, 1024]}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10}
                />
                <spotLight
                    position={[-5, 10, 2]}
                    angle={0.3}
                    penumbra={0.5}
                    intensity={0.8}
                    castShadow
                    shadow-bias={-0.0001}
                    color="#ffcc99"
                />
                <spotLight
                    position={[5, 10, 2]}
                    angle={0.3}
                    penumbra={0.5}
                    intensity={0.6}
                    castShadow
                    shadow-bias={-0.0001}
                    color="#ffcc99"
                />
                <pointLight position={[0, 5, -5]} intensity={0.3} color="#ffffff" />
                <hemisphereLight intensity={0.4} color="#ffffff" groundColor="#444444" />
                <OrbitControls enableZoom={true} zoomSpeed={1.5} />
                <Environment preset="warehouse" background={false} />
                <Suspense fallback={null}>
                    <Model modelPath={model.path} position={model.position} scale={model.scale} rotation={model.rotation} />
                </Suspense>
            </Canvas>
        </div>
    );
};

export default FullScreenModel;