import React from 'react';
import { Block, Col, Icon, Row } from '../../../components/Component';
import { Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import tabimg1 from '../../../images/siloimages/chooseusimg.png';
import tabimg2 from '../../../images/siloimages/B1.png';
import tabimg3 from '../../../images/siloimages/D11.png';

const cardData = [
  { icon: 'trend-up', title: 'Prime for growth', text: 'Empowering progress through tailored growth strategies is one of our key services.' },
  { icon: 'shield-check', title: 'Security you can trust', text: "We've got your back with industry-standard security measures." },
  { icon: 'globe', title: 'Bring all with you', text: 'Seamless collaboration are fundamental aspect of our top-tier service offerings' },
  { icon: 'cloud', title: 'Cloud storage', text: "You'll never run out of storage with silocloud.com's plans. All in one place." }
];


const ChooseUs = () => {
  return (
    <Block className="mt-2 mt-md-4 position-relative">
      <div className="container-fluid py-md-5 py-0 z-1 position-relative" id='whysilo'>
        <Row className="py-3 g-2 mx-md-2 mx-2 bg-white rounded-5 shadow-lg" >
          <div className='d-flex justify-content-center mx-md-5 py-md-3 py-2'>
            <h3 className="ff-alt fw-semibold text-capitalize text-center" style={{ color: "#17253D" }}>
              Why Choose Silo Secured Data?
            </h3>
          </div>
          <Col md={12} lg={6} className="align-self-center">
            <Row className="g-2">
              {cardData.map((card, index) => (
                <Col md={6} key={index}>
                  <Card className="card-bordered bg-transparent rounded-5 h-100 border-0" inverse>
                    <CardBody className="card-inner p-2">
                      <CardHeader className='text-black bg-transparent rounded-5 px-0'>
                        <Icon name={card.icon} className="fs-3" />
                      </CardHeader>
                      <CardTitle className='fs-4 fw-semibold ff-alt' style={{ color: "#17253D" }}>{card.title}</CardTitle>
                      <CardText className='ff-alt fs-6' style={{ color: "#17253D" }}>{card.text}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={12} lg={6} className="align-self-center">
            <div className="container p-0">
              <div className='d-flex justify-content-center rounded-5'>
                <img src={tabimg1} alt="align-self-center" className='rounded-5' />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='A3 d-md-inline-block d-none'>
        <img src={tabimg2} alt="" width='85%' />
      </div>
      <div className='A2 d-md-inline-block d-none'>
        <img src={tabimg3} alt="" width='75%' />
      </div>
    </Block>
  );
};

export default ChooseUs;