import React, { useState } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";

import { useTheme, useThemeUpdate } from '../provider/Theme';
import { Button } from "reactstrap";
import { LinkItem, LinkList } from "../../components/Component";
import { NavLink } from "react-router-dom";

const Header = ({ fixed, className, ...props }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const [active, setActive] = useState("/");
  const handleSetActive = (to) => {
    setActive(to);
  };
  const headerClass = classNames({
    "nk-header is-regular": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  const closeMenu = () => {
    if (theme.sidebarVisibility) {
      themeUpdate.sidebarVisibility();
    }
  };
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap justify-content-between">
          <div className="nk-header-brand">
            <Logo />
          </div>
          <div className="nk-menu-trigger me-sm-2 d-lg-none">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div
            className={`nk-header-menu ms-auto ${theme.sidebarMobile ? "mobile-menu" : ""}  ${theme.sidebarVisibility ? "nk-header-active" : ""
              }`}
          >
            <div className="nk-header-mobile p-2 justify-content-between ">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger me-n2">
                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="arrow-left" click={themeUpdate.sidebarVisibility} />
              </div>
            </div>
            <div>
              <nav className="navbar navbar-expand-lg">
                <div className="container-fluid pt-lg-0 pt-4">
                  <div className="" id="navbarNav">
                    <ul className="navbar-nav">
                      <LinkList className="mx-2 border-0 p-0 align-self-center">
                        <NavLink
                          to="/"
                          className={({ isActive }) =>
                            `scrollto textunderline px-2 ff fw-bold fs-6 text-black ${isActive ? 'active' : ''}`
                          }
                          onClick={closeMenu}
                        >
                          Home
                        </NavLink>
                      </LinkList>
                      <LinkList className="mx-2 border-0 p-0 align-self-center">
                        <NavLink
                          to="/portfolio"
                          className={({ isActive }) =>
                            `scrollto textunderline px-2 ff fw-bold fs-6 text-black ${isActive ? 'active' : ''}`
                          }
                          onClick={closeMenu}
                        >
                          Portfolio
                        </NavLink>
                      </LinkList>
                      <LinkList className="mx-2 border-0 p-0 align-self-center">
                        <NavLink
                          to="/services"
                          className={({ isActive }) =>
                            `scrollto textunderline px-2 ff fw-bold fs-6 text-black ${isActive ? 'active' : ''}`
                          }
                          onClick={closeMenu}
                        >
                          Services
                        </NavLink>
                      </LinkList>
                      <LinkList className="mx-2 border-0 p-0 align-self-center">
                        <a
                          href={process.env.REACT_APP_BLOG_POST_URL}
                          className={`scrollto textunderline px-2 ff fw-bold fs-6 text-black pe-auto`}
                          duration={100}>Blog</a>
                      </LinkList>
                      <LinkList className="nav-item px-2 align-self-center border-0">
                        <LinkItem to={'contact'} >
                          <Button
                            className="btn border-0 ff fw-bold gradient-button"
                            onClick={closeMenu}>
                            Contact Us
                          </Button>
                        </LinkItem>
                      </LinkList>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          {theme.sidebarVisibility && <div className="nk-header-overlay" onClick={themeUpdate.sidebarVisibility}></div>}
        </div>
      </div>
    </div>
  );
};
export default Header;
