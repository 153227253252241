import React from 'react';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Icon } from '../../../components/Component';
import WhitelabeledButton from '../../axiomlayout/components/WhitelabeledButton';

const WelcomeModal = ({
    isOpen, onClose, title, subtitle, leftColumnTitle, leftColumnItems, rightColumnTitle, rightColumnItems, 
    ctaButtonText, ctaButtonLink, footerTitle, phoneNumber, email, 
    fontClass = 'ff'
}) => {
    return (
        <Modal size="lg" isOpen={isOpen}>
            <button className="close" onClick={onClose}>
                <Icon name="cross" />
            </button>
            <ModalBody>
                <Card className='justify-content-center'>
                    <CardHeader className="px-0 bg-transparent text-center">
                        <h2 className={`${fontClass} fs-5 fw-bold text-primary`}>{title}</h2>
                        <p className={`${fontClass} sub-text`}>{subtitle}</p>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <h6 className={fontClass}>{leftColumnTitle}</h6>
                                <ul className="list">
                                    {leftColumnItems.map((item, index) => (
                                        <li key={index} className={fontClass}>{item}</li>
                                    ))}
                                </ul>
                            </Col>
                            <Col>
                                <h6 className={fontClass}>{rightColumnTitle}</h6>
                                <ul className="list">
                                    {rightColumnItems.map((item, index) => (
                                        <li key={index} className={fontClass}>{item}</li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <WhitelabeledButton
                    to={ctaButtonLink}
                    className="text-center"
                    buttonText={ctaButtonText}
                    buttonOutline
                    buttonColor="primary"
                />
            </ModalBody>
            <ModalFooter className="bg-light text-center justify-content-center">
                <h3 className={`${fontClass} text-primary`}>{footerTitle}</h3>
                <div className={fontClass}>
                    <span>
                        Call us at:
                        <a href={`tel:${phoneNumber}`}> {phoneNumber}</a>
                    </span>
                </div>
                <div className={fontClass}>
                    <span>
                        Email us at:
                        <a href={`mailto:${email}`}> {email}</a>
                    </span>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default WelcomeModal;