import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { BarLoader } from 'react-spinners';

const PortfolioTab = ({ items, titleClassName = "ff-alt text-primary" }) => {
    const [loadingStates, setLoadingStates] = useState({});

    const handleImageLoad = (imageName) => {
        setLoadingStates(prev => ({ ...prev, [imageName]: false }));
    };
    return (
        <div className='col-12 py-md-2'>
            <Row className='my-md-3 h-md-400px'>
                {items.map((item, index) => (
                    <div key={index} className="col-md-4 mt-2 content position-relative">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <div className="screen">
                                {loadingStates[item.id] !== false && (
                                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                                        <BarLoader color={"#123abc"} height={3} width={"100%"} />
                                    </div>
                                )}
                                <img src={item.image} alt={item.title} onLoad={() => handleImageLoad(item.id)} style={{ display: loadingStates[item.id] === false ? 'block' : 'none' }} />
                            </div>
                            <div className="text-center pt-2">
                                <h5 className={titleClassName}>{item.title}</h5>
                            </div>
                        </a>
                    </div>
                ))}
            </Row>
        </div>
    );
};

export default PortfolioTab;