import React from 'react'
import { Button } from 'reactstrap'
import bgimg1 from '../../../images/axiom_img/background2.png';
import { Link } from 'react-router-dom';


const Contact = () => {
    return (
        <React.Fragment>
            <div className="container py-2 my-md-5 position-relative" style={{
                backgroundImage: process.env.REACT_APP_GRADIANT_BACKGROUND
            }}>
                <div className='row justify-content-evenly align-items-center'>
                    <div className='col-md-6 p-2 p-md-0'>
                        <p className='text-white fs-3 p-md-3 p-0 ff'>Ready to unlock the potential of your product?</p>
                    </div>
                    <div className='col-md-4 p-2 p-md-0'>
                        <div className='p-md-3 p-0 d-flex justify-content-md-end justify-content-center  '>
                            <Link to={'/contact'}>
                                <Button
                                    className="btn border-0 ff fw-bold gradient-button"
                                >
                                    Contact Us
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='position-absolute d-none d-md-block opacity-50 icon_img'>
                    <img src={bgimg1} alt="background_img" className='h-425px bgimg1' style={{ transform: 'rotate(-30deg)' }} />
                </div>
            </div>

        </React.Fragment>
    )
}

export default Contact
